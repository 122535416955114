#edit-portday-form {
    display: flex;
    flex-direction: column;
    width: 800px;

}
.port-day-edit-warning {
    width: 100%;
}

.update-port-day-actions {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: .1rem dashed grey;
}

.input-with-error__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.input-grouping {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
}

.input-margin-left {
    margin-left: 1rem;
}