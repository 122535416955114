.itinerary-row-country {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.itinerary-row-country img {
    width: 54px;
    border-radius: 5px;
    overflow: hidden;
}