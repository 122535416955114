.ship-list-card {
    min-width:250px;
    display: flex;
    box-shadow: none;
    background-color: transparent;
    align-items: stretch;
    max-height: 90vh;
    overflow-y: scroll;
}

.ship-list-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.ship-list-filter {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 20px;
    background-color: rgba(255,255,255, .5);
    border-radius: 5px;

}
.ship-list-filter form {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.ship-list-filter form p {
    width: 100%;
    font-size: 2rem;
    font-weight: 300;
    margin: 3px;
}

.input-search-wrapper {
    width:fit-content !important;
    margin: 0 .5rem;
    margin-bottom: 1rem;
   
}

.search-lastname {
    flex-grow: 2;
}

.search-button {
    margin: 0 ;
    margin-bottom: 1rem;
    background-color: var(--primary);
    border-radius: 5px;
    padding:10px 20px;
    border: none;
    outline: none;
    color: white;
    font-size: 1.7rem;
    font-weight: 500;
    
    height: fit-content;
    transition: .3s;
}

.search-button:hover {
    background-color: var(--primary-500);
}

.ship-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}