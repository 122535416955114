#port-detail {
    display: flex;
    flex-wrap: wrap;
    max-height: 90vh;
    overflow-x: scroll;
}

.port-detail-itinerary__wrapper {
    width: 50%;
    max-width: 1000px;
    padding: 10px;
}

.port-detail-box {
    padding: 1rem;
}

.port-image {
    width: 600px;
    height: 400px;
}

.port-image img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.last-updated-port{
    width: 100%;
    color: gray;
    font-size: 1.6rem;
    text-align: right;
    padding: 2rem;
    border-top: 1px solid #cfcfcf;
}

@media only screen and (max-width:1349px) {
    .port-detail-itinerary__wrapper {
        width: 95%;
        
    }
}