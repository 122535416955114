.company-badge {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--secondary);
   
}

.badge-img {
    max-width: 60px;
}

.company-badge h2 {
    margin-left: 10px;
    font-size: 2.5rem !important;
    font-weight: 300 !important;
}