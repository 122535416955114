@import url(https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);

:root {
  --primary: #0E4160; 
  --primary-500: #22536D;

  --secondary: #5E5E5E;
  --secondary-500: #333;

  --input-color: #99A3BA;
  --input-border: #99A3BA;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #27A1B7;

  --group-color: white;
  --group-border: var(--input-border);
  --group-background: #333;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}


html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Zilla Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: -webkit-flex;
  display: flex;
  color: #0E4160;
  color: var(--primary);
  background-color: whitesmoke;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
}

.left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 9rem;
  margin-bottom: 5.6rem;
  width: 100%;
  max-width: 1750px;
  min-height: 500px;
}

a {
  color: #0E4160;
  color: var(--primary);
  text-decoration: none;
}

.clear-header {
  padding-top: 90px !important;
}

a:hover {
  color: #22536D;
  color: var(--primary-500);
}

#root {
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.text-error {
  font-size: 1.2rem;
  color: darkred;
}
.logo__wrapper {
    margin: 15px 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100px;  
    width: 100%;
    overflow: hidden;
}

.logo-img{
    height: 100%;

}
.main-nav__wrapper {
    width: 100%;
}

.nav-links {
    margin: 20px 0;
    padding: 0;
    width: 100%;
    list-style: none;

}

.nav-button {
    border: none;
    width: 100%;
}

.nav-links li a, .nav-button {
    display: block;
    color: var(--secondary-500);
    text-decoration: none;
    margin: 0;
    padding: 15px;
    font-size: 1.3rem;
    font-weight: 500;
    background-color: lightgray;
    border-bottom: 1px solid #c3c3c3;
    transition: .6s;
    z-index: -1;
}

.nav-links li a:hover,
.nav-button:hover,
.nav-links li a:active, 
.nav-button:active, 
.nav-links li a:focus, 
.nav-button:focus 
{
    background-color: whitesmoke;
}

.sidebar__wrapper {
  position: fixed;
  min-width: 150px;
  height: 100vh;
  background-color: #e5e5e5;
  border-left: 1px solid #a0a0a0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: stretch;
          align-items: stretch;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
  z-index: 500;
}

.link-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  -webkit-align-items: center;
          align-items: center;
}

.avatar-icon-wrapper {
    margin: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    z-index: 15;
}

.avatar-icon-wrapper img {
    width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.page-header__wrapper {
    position: fixed;
    margin: 0;
    width: calc(100% - 150px);
    padding: 5px;
    background-color: var(--primary);
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    height: 45px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    z-index: 500;
  }

  .page-title {
      margin: 0;
      font-weight: 200;
      font-size: 2.5rem;
      margin-left: 20px;
  }

  .tutor-welcome__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 10px;
  }

  .welcome-text {
      padding: 0;
      margin-right: 10px;
      font-size: 1.8rem;
      font-weight: 200;
  }

  .logout {
      background-color: transparent;
      display: block;
      border: none;
      color: white;
      padding: 10px;
      font-size: 2rem;
      transition: .3s;
      cursor: pointer;
  }

  .logout:hover {
      color: var(--secondary-500);
  }

  .logout:active, 
  .logout:focus {
    border: none;
    outline: none;
}
  
.sub-menu__wrapper {
    margin: 0;
    margin-bottom: 5px;
    margin-top: 55px;
    position: fixed;
    width: calc(100% - 150px);
    z-index: 499;
    padding: 0;
    height: 40px;
    background-color:#333;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);

}

.sub-menu-list {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    font-size: 1.5rem;
    
}

.sub-menu-item {
    padding: 3px 15px;
    color:white;
    border-right: 1px dotted var(--primary);
}

.sub-menu-item-btn {
    background-color: transparent;
    outline: none;
    border: none;
    color:white;
    font-size: 1.5rem;
    transition: .3s;
    cursor: pointer;
    display: block;
}

.sub-menu-item-btn:hover,
.sub-menu-item-btn:focus {
    outline: none;
    border: none;
    color: var(--primary);
}


.button {
    margin: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 5px;
    border-width: 1px;
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all .35s;
}

.button span{
    position: relative;
    z-index: 2;
  }

.button:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--primary-500);
    transition: all .35s;
    border-radius: 5px;
  }

.button:hover:after {
    width: 100%;
  }

.button:focus, .button:active {
    border: 1px solid white;
    outline: none;
}

.button-40 {
    width: 40%;
}

.button-50 {
    width: 50%;
}

.button-100 {
    width: 100%;
}

.less-margin {
    margin: .3rem;
}

.button-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
}
.button-primary:after{
    background-color: var(--primary-500);
}


.button-secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}
.button-secondary:after{
    background-color: var(--secondary-500);
}

.button-danger {
    background-color: #B70F0F;
    border: 1px solid #D53D3D;
    color: white;
}
.button-danger:after{
    background-color: #770808;
}

.button-green {
    background-color: #168612;
    border: 1px solid #10640d;
    color: white;
}
.button-green:after{
    background-color: #10640d;
}

.button-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #7B6715;
}
.button-warning:after{
    background-color: #e0a800;
}

.button-outline-primary{
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.button-outline-primary:after{
    background-color: white;
}

.button-disabled {
    background-color: gray;
    color: darkgray;
    border: 1px solid darkgray;
}

.button-disabled:after {
    background-color: gray;
}





.lds-roller {
    margin: 0 auto;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    padding: 10px;
    padding-bottom: 50px;
    border-radius: 5px;
    background-color: rgba(255,255,255, 0.0);
  }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background:  var(--primary);;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }

  .lds-roller-text {
      margin-top: 90px;
      font-size: 20px;
      color: var(--primary);
  }
  @-webkit-keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

.course-form-editor-wrapper {
 font-size: 1.6rem;
}

.editor {
    font-size: 1.6rem;
    background-color: white;
    padding: 2rem;
}


#port-detail {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-height: 90vh;
    overflow-x: scroll;
}

.port-detail-itinerary__wrapper {
    width: 50%;
    max-width: 1000px;
    padding: 10px;
}

.port-detail-box {
    padding: 1rem;
}

.port-image {
    width: 600px;
    height: 400px;
}

.port-image img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.last-updated-port{
    width: 100%;
    color: gray;
    font-size: 1.6rem;
    text-align: right;
    padding: 2rem;
    border-top: 1px solid #cfcfcf;
}

@media only screen and (max-width:1349px) {
    .port-detail-itinerary__wrapper {
        width: 95%;
        
    }
}
.input-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.form-field {
    display: block;
    width: 100%;
    padding: .8rem 1.6rem;
    line-height: 2.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border .3s ease; 
}

.no-group {
    border-radius: 6px!important;
}

.form-field::-webkit-input-placeholder {
    color: var(--input-placeholder);
}

.form-field:-ms-input-placeholder {
    color: var(--input-placeholder);
}

.form-field::placeholder {
    color: var(--input-placeholder);
}

.form-field:focus {
    outline: none;
    border-color: var(--input-border-focus);
}

.form-group {
    margin: 0;
    position: relative;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.form-padding {
    margin: 0 10%;
}

.form-group span, .form-group .form-field {
    white-space: nowrap;
    display: block;
    border-radius: 6px;
}

.form-group .form-field:not(:first-child):not(:last-child),
.form-group span:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.form-group .form-field:first-child,
.form-group span:first-child {
    border-radius: 6px 0 0 6px;
}

.form-group .form-field:last-child,
.form-group span:last-child{
    border-radius: 0 6px 6px 0;
}

.form-group .form-field:not(:first-child),
.form-group .span:not(:first-child) {
    margin-left: -.1rem;
}


.form-group .form-field {
    position: relative;
    z-index: 1;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
}

.form-group span {
    text-align: center;
    padding: .8rem 1.2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background .3s ease, border .3s ease, color .3s ease;
}

.form-group:focus-within span {
        color: var(--group-color-focus);
        background: var(--group-background-focus);
        border-color: var(--group-border-focus);
    }

   

   .inputError {
       
        color: darkred;
        font-weight: 600;
    
}
.update-port-lang-form {
    display: -webkit-flex;
    display: flex;
}

.btn-lang-update {
    margin: 0;
    margin-left: 10px;
    margin-bottom: 1rem;
}
.card {
    margin: 5px;
    border-radius: 10px;
    background-color: #EEEEEE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .3);
    
}

.card-header {
    border-radius: 10px 10px 0px 0px;
    padding-left: 10px;
    background-color: var(--secondary);
    color: white;
}

.card-header h3 {
    margin: 0;
    padding: 5px;
}


.primary-card {
    background-color: var(--primary);
}

.secondary-card {
    background-color: var(--secondary);
}

.dark-card {
    background-color: #333;
}
.ship-list-card {
    min-width:250px;
    display: -webkit-flex;
    display: flex;
    box-shadow: none;
    background-color: transparent;
    -webkit-align-items: stretch;
            align-items: stretch;
    max-height: 90vh;
    overflow-y: scroll;
}

.ship-list-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}

.ship-list-filter {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-content: center;
            align-content: center;
    margin-bottom: 20px;
    background-color: rgba(255,255,255, .5);
    border-radius: 5px;

}
.ship-list-filter form {
    margin-top: 15px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.ship-list-filter form p {
    width: 100%;
    font-size: 2rem;
    font-weight: 300;
    margin: 3px;
}

.input-search-wrapper {
    width:-webkit-fit-content !important;
    width:-moz-fit-content !important;
    width:fit-content !important;
    margin: 0 .5rem;
    margin-bottom: 1rem;
   
}

.search-lastname {
    -webkit-flex-grow: 2;
            flex-grow: 2;
}

.search-button {
    margin: 0 ;
    margin-bottom: 1rem;
    background-color: var(--primary);
    border-radius: 5px;
    padding:10px 20px;
    border: none;
    outline: none;
    color: white;
    font-size: 1.7rem;
    font-weight: 500;
    
    height: -webkit-fit-content;
    
    height: -moz-fit-content;
    
    height: fit-content;
    transition: .3s;
}

.search-button:hover {
    background-color: var(--primary-500);
}

.ship-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.ship-item__wrapper {
    background-color: white;
    margin: 10px 10px;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:flex-start;
            justify-content:flex-start;
    min-width: 400px;
    transition: .3s;
}

.ship-item__wrapper a {
    text-decoration: none;
}

.ship-item__wrapper:hover {
    background-color: #eee;
}

.ship-item-avatar {
   margin: 0;
   padding:10px;
   width: 80px;
   display: -webkit-flex;
   display: flex;
   -webkit-justify-content: center;
           justify-content: center;
   background-color: #eee;
   border-radius: 5px 0 0 5px;
}

.ship-item-data {
    padding: 10px;
    width: 100%;
}

.ship-item-name {
    margin: 0;
    color: var(--secondary-500);
    font-size: 2rem;
}

.ship-item-id__wrapper,
.ship-item-email__wrapper,
.ship-item-joined__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}
.ship-item-id,
.ship-item-label,
.ship-item-email, 
.ship-item-joined  {
    margin: 0;
}

.ship-item-label  {
    font-weight: 300;
    color: var(--secondary);
    margin-right: 5px;
}

.ship-item-email__wrapper {
    margin-top: 5px;
}

.ship-item-email {
    font-size: 1.4rem;
}

.ship-item-joined__wrapper {
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    font-size: 1.2rem;
}

.ship-item-joined,
.joined-label {
    font-size: 1rem;
    color: var(--secondary-500);
}

.company-icon-wrapper {
    margin: 0;
    position: relative;
    width: 45px;
    height: 45px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    z-index: 15;
}

.company-icon {
    
   width: 100%;
   object-fit: fill;
}
.itinerary-row-country {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

}

.itinerary-row-country img {
    width: 54px;
    border-radius: 5px;
    overflow: hidden;
}
#edit-portday-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 800px;

}
.port-day-edit-warning {
    width: 100%;
}

.update-port-day-actions {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    border-top: .1rem dashed grey;
}

.input-with-error__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    width: 50%;
}

.input-grouping {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    
}

.input-margin-left {
    margin-left: 1rem;
}
#choose-port {
    min-width: 500px;
    margin-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-items: stretch;
            align-items: stretch;
}

.choose-port-search {
    display: -webkit-flex;
    display: flex;
}

.choose-port-list {
    height: 380px;
    -webkit-flex-grow: 2;
            flex-grow: 2;
    overflow-y: scroll;
    background-color: whitesmoke;
    border-radius: 5px;
    border: 1px gray solid;
}

.choose-port-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

}
.choose-port-actions-btn {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.choose-port-item {
    margin: 0;
    padding: 20px;
    text-align: center;
    border-bottom: 1px lightgray solid;
    transition: .3s;

}

.choose-port-item p {
    margin: 0;
}

.choose-port-item:hover {
    background-color: lightgray;
}

.choose-port-item:focus {
    background-color: rgb(160, 191, 216);
}

.choose-port-warn {
    margin-top: -5px;
    width: 100%;
    text-align: left;
    padding-left: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    color: darkred;
}



table {
    margin: 0px auto;
    width: 100% !important;
    font-size: 1.6rem;
}

tbody{
    width: 100%;
}


.noHover{
    pointer-events: none;
    cursor:none;
}


.theader {
    color: white;
    
}

th, td {
    padding: 15px;
  }

th {
    
    background: var(--primary);
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    
    
  }

  tr {
    transition: .3s;
    cursor: pointer;
  }


tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #e3e3e3;
    color: #333;
}

.table-footer {
    margin: 0 auto;
    width: 100%;
    max-width: 1750px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 5px 0;
    
}
.company-badge {
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    color: var(--secondary);
   
}

.badge-img {
    max-width: 60px;
}

.company-badge h2 {
    margin-left: 10px;
    font-size: 2.5rem !important;
    font-weight: 300 !important;
}
#updater {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
    margin: 10px;
    z-index:0;

    background-color: whitesmoke;
    border-radius: 5px;
}

.updater-form {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items:center;
            align-items:center;
}

.get-data-btn {
 width: 150px;
}

.get-data-input {
    margin: 2px;
    max-width: 200px;
}

.updater-message p{
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
}

.updater-error-message {
    color: darkred;
}
#ship-detail {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 90vh;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    overflow-y: scroll;

}

.ship-detail-info__wrapper {
    margin: 0;
    margin-top: 20px;
    padding: 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-items: stretch;
            align-items: stretch;
}

.ship-detail-box {
    margin: 5px;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    min-height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.ship-detail-placeholder {
    margin: 5px;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    background-color: lightgrey;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.ship-title h1 {
    font-size: 3rem;
    margin-bottom: 0;
    width: 100%;
    border-bottom: 1px dotted var(--primary);
}

.ship-info,
.ship-title,
.ship-today {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.ship-today {max-width: 500px;
}



.ship-info-title {
    margin: 0;
    font-size: 2rem;
    width: 100%;
    
    
}
.ship-detail-info-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    
    -webkit-justify-content: space-evenly;
    
            justify-content: space-evenly;
}
.ship-detail-info-text p {
    margin: 5px 0;
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--secondary);
}

.primary {
    color: var(--primary);
}

.ship-last-port {
    color: red;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
}

.last-day-warn {
    color:  #333;;
}

.scrap-warning-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 300px;
}

.scrap-warning-wrapper h1 {
    margin: .5rem;
}

.scrap-warning-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: stretch;
            justify-content: stretch;
}

.scrap-warning-actions button {
    width: 40%;
}

#add-ship {
    overflow-x: hidden;
    width: calc(100vw - 290px);
}

.add-ship-company-list {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 170px;
    overflow-x: scroll;
    background-color: darkgray;
}



.company-item-card {
display: -webkit-flex;
display: flex;
-webkit-flex-direction: column;
        flex-direction: column;
min-width: 110px;
height: 120px;
-webkit-justify-content: center;
        justify-content: center;
-webkit-align-items: center;
        align-items: center;
text-align: center;
padding: 10px 20px;
transition: .3s;
}

.company-item-card:hover {
    min-width: 120px;
    height: 130px;
    background-color: whitesmoke;
}

.company-item-card:active {
    min-width: 110px;
    height: 120px;
    background-color: white;
}

.company-item-card h2 {
    margin: .5rem;
}

.auth-wrapper {
    width: 90%;
    max-width: 500px;
    padding-top: 10%;
}
.login-card,
.forgot-card {
    margin-top: 15%;
    padding-bottom: 2rem;
    font-size: 1.6rem;
}

.login-card h1 {
    padding-top: 3rem;
    font-weight: 300;
    text-align: left;
    margin-bottom: 0;
    margin-left: 20%;
}
.login-card p {
    margin-left: 20%;
    margin-top: 0;
   font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    color: var(--primary-500);
}


.login-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

}

.login-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 50%;
    
}

.enrol-btn {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    width: 30%;
}

.enrol-cta-container {
    margin: 7px;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.enrol-cta-container p {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
}

.forgot-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}


.reset-sent-notice {
    color: var(--secondary);
    margin: 0;
    padding: 10px 20px;
}

.spinner-center {
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
}
.avatar-wrapper {
    margin: 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid var(--primary);
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    z-index: 15;
}

.avatar-wrapper img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
.upload-gpimg-btn {
    font-size: 2rem;
    font-weight: 400;
}

input[type="file"] {
    display: none;
}

.gen-image-preview {
    max-height: 200px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid var(--primary);
}

.file-selection__wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
#content-image-upload__wrapper {
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
}

.content-image-upload-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

#my-account-settings {
    margin: 20px 0;
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background: whitesmoke;
    padding: 20px;
    border-radius: 8px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.my-account-settings-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border: 1px solid var(--primary);
    border-radius: 8px;
    width: 100%;


}

.my-account-settings-title {
    margin-top: -1rem;
    background-color: whitesmoke;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px;
    margin-left: 20px;
}

.my-account-settings-inner-content {
    padding: 10px;
    display: -webkit-flex;
    display: flex;

}

.avatar-settings-container {
    padding: 0 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;

}

.personal-settings-container {
    margin-left: 20px;
    display: -webkit-flex;
    display: flex;
}

.personal-settings-container form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.personal-update-btn {
    margin: 0;
    width: 100%;
}

.personal-settings-container-p {
    margin: 0;
    margin-bottom: 3px;
    color: grey;
}

.update-avatar-modal__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

main {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: stretch;
          align-content: stretch;
  padding: 0 ;
}

.page__wrapper {
  margin-left: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-grow: 2;
          flex-grow: 2;
}

.page-content {
  position: fixed;
  width: calc(100vw - 149px);
  padding: 0px;
  
  -webkit-flex-grow: 2;
  
          flex-grow: 2;
}

.spinner-margin-top {
  margin-top:100px;
}
