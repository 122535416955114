.ship-item__wrapper {
    background-color: white;
    margin: 10px 10px;
    border-radius: 5px;
    display: flex;
    justify-content:flex-start;
    min-width: 400px;
    transition: .3s;
}

.ship-item__wrapper a {
    text-decoration: none;
}

.ship-item__wrapper:hover {
    background-color: #eee;
}

.ship-item-avatar {
   margin: 0;
   padding:10px;
   width: 80px;
   display: flex;
   justify-content: center;
   background-color: #eee;
   border-radius: 5px 0 0 5px;
}

.ship-item-data {
    padding: 10px;
    width: 100%;
}

.ship-item-name {
    margin: 0;
    color: var(--secondary-500);
    font-size: 2rem;
}

.ship-item-id__wrapper,
.ship-item-email__wrapper,
.ship-item-joined__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}
.ship-item-id,
.ship-item-label,
.ship-item-email, 
.ship-item-joined  {
    margin: 0;
}

.ship-item-label  {
    font-weight: 300;
    color: var(--secondary);
    margin-right: 5px;
}

.ship-item-email__wrapper {
    margin-top: 5px;
}

.ship-item-email {
    font-size: 1.4rem;
}

.ship-item-joined__wrapper {
    margin-top: 10px;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    font-size: 1.2rem;
}

.ship-item-joined,
.joined-label {
    font-size: 1rem;
    color: var(--secondary-500);
}