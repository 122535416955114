.company-item-card {
display: flex;
flex-direction: column;
min-width: 110px;
height: 120px;
justify-content: center;
align-items: center;
text-align: center;
padding: 10px 20px;
transition: .3s;
}

.company-item-card:hover {
    min-width: 120px;
    height: 130px;
    background-color: whitesmoke;
}

.company-item-card:active {
    min-width: 110px;
    height: 120px;
    background-color: white;
}

.company-item-card h2 {
    margin: .5rem;
}