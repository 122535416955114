table {
    margin: 0px auto;
    width: 100% !important;
    font-size: 1.6rem;
}

tbody{
    width: 100%;
}


.noHover{
    pointer-events: none;
    cursor:none;
}


.theader {
    color: white;
    
}

th, td {
    padding: 15px;
  }

th {
    
    background: var(--primary);
    position: sticky;
    top: -1px;
    
    
  }

  tr {
    transition: .3s;
    cursor: pointer;
  }


tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #e3e3e3;
    color: #333;
}

.table-footer {
    margin: 0 auto;
    width: 100%;
    max-width: 1750px;
    display: flex;
    justify-content: flex-start;
    padding: 5px 0;
    
}