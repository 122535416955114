#add-ship {
    overflow-x: hidden;
    width: calc(100vw - 290px);
}

.add-ship-company-list {
    display: flex;
    width: 100%;
    height: 170px;
    overflow-x: scroll;
    background-color: darkgray;
}


