#ship-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: stretch;
    overflow-y: scroll;

}

.ship-detail-info__wrapper {
    margin: 0;
    margin-top: 20px;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.ship-detail-box {
    margin: 5px;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    min-height: 200px;
    display: flex;
    flex-direction: column;
}

.ship-detail-placeholder {
    margin: 5px;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ship-title h1 {
    font-size: 3rem;
    margin-bottom: 0;
    width: 100%;
    border-bottom: 1px dotted var(--primary);
}

.ship-info,
.ship-title,
.ship-today {
    flex-grow: 1;
}

.ship-today {max-width: 500px;
}



.ship-info-title {
    margin: 0;
    font-size: 2rem;
    width: 100%;
    
    
}
.ship-detail-info-text {
    display: flex;
    flex-direction: column;
    
    justify-content: space-evenly;
}
.ship-detail-info-text p {
    margin: 5px 0;
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--secondary);
}

.primary {
    color: var(--primary);
}

.ship-last-port {
    color: red;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
}

.last-day-warn {
    color:  #333;;
}

.scrap-warning-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
}

.scrap-warning-wrapper h1 {
    margin: .5rem;
}

.scrap-warning-actions {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

.scrap-warning-actions button {
    width: 40%;
}