#updater {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px;
    z-index:0;

    background-color: whitesmoke;
    border-radius: 5px;
}

.updater-form {
    display: flex;
    justify-content: space-around;
    align-items:center;
}

.get-data-btn {
 width: 150px;
}

.get-data-input {
    margin: 2px;
    max-width: 200px;
}

.updater-message p{
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
}

.updater-error-message {
    color: darkred;
}