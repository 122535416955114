.company-icon-wrapper {
    margin: 0;
    position: relative;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 15;
}

.company-icon {
    
   width: 100%;
   object-fit: fill;
}