
.course-form-editor-wrapper {
 font-size: 1.6rem;
}

.editor {
    font-size: 1.6rem;
    background-color: white;
    padding: 2rem;
}

