#choose-port {
    min-width: 500px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}

.choose-port-search {
    display: flex;
}

.choose-port-list {
    height: 380px;
    flex-grow: 2;
    overflow-y: scroll;
    background-color: whitesmoke;
    border-radius: 5px;
    border: 1px gray solid;
}

.choose-port-actions {
    display: flex;
    flex-wrap: wrap;

}
.choose-port-actions-btn {
    flex-grow: 1;
}

.choose-port-item {
    margin: 0;
    padding: 20px;
    text-align: center;
    border-bottom: 1px lightgray solid;
    transition: .3s;

}

.choose-port-item p {
    margin: 0;
}

.choose-port-item:hover {
    background-color: lightgray;
}

.choose-port-item:focus {
    background-color: rgb(160, 191, 216);
}

.choose-port-warn {
    margin-top: -5px;
    width: 100%;
    text-align: left;
    padding-left: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    color: darkred;
}


